<template>
  <v-container fluid class="custom_bg">
    <div class="not-found">
      <img src="@/assets/png/424.png" alt="404 Illustration" class="error-image">
      <h1>{{ $t('setup.databaseCreation') }}</h1>
      <p>{{ $t('setup.redirectNotice') }}</p>
    </div>
  </v-container>
</template>



<script>
import { mapState } from 'vuex';
import ProjectsService from '@/services/api/project';

export default {
  name: "Setup",
  data()
  {
    return {
      pollInterval: null,
    };
  },
  computed: {
    ...mapState({
      currentAccount: state => state.user.currentAccount,
    }),
  },
  mounted()
  {
    this.startPolling();
  },
  beforeDestroy()
  {
    this.stopPolling();
  },
  methods: {
    startPolling()
    {
      this.pollInterval = setInterval(() =>
      {
        this.pollProjects();
      }, 10000); // 10 seconds
    },
    stopPolling()
    {
      clearInterval(this.pollInterval);
    },
    async pollProjects()
    {
      if (!this.currentAccount || !this.currentAccount.handle) {
        console.error('Current account or handle is not available');
        return;
      }

      const projectService = ProjectsService(this.$api);
      const searchParams = new URLSearchParams();
      searchParams.set('status', 'active');
      searchParams.set('includeCount', true);

      try {
        const response = await projectService.getProjects(this.currentAccount.handle, searchParams.toString());
        if (response.data.projects.length > 0) {
          const comingFromAuth = ["/login", "/signup", "/"].includes(this.$router.history._startLocation);
          const dest = comingFromAuth
            ? `/${this.currentAccount.handle}/projects`
            : this.$router.history._startLocation;

          this.$router.push(dest);
        }
      } catch (error) {
        console.error('Error polling projects:', error);
      }
    },
  },
};
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 50px;
  max-width: 600px;
  margin: 0 auto;
}

.error-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 24px;
  text-align: center;
}
</style>
